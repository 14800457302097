import React from 'react';
import Home from '../components/Home';

function HomeContainer() {

  return (

    <div className="containers" >
      <Home />
    </div>
    
  );
  
}

export default HomeContainer;